import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CarCart from "./CarCart";
import ModelCart from "./ModelCart";
import Logo from "../assets/autohall.png";

const LandingPage = ({
  setBrand,
  setStep,
  step,
  setModel,
  model,
  brand,
  brands,
  cars,
  versions,
  brandIsPassed,
  getSegment,
  color,
  loading,
  localBrand,
  setIsBrandLink,
  isBrandLink,
  isBrandParam,
}) => {
  console.log(brand);
  return (
    <Container color={color}>
      <div
        className="bg"
        style={brand ? { background: brand.brand_color } : {}}
      />
      <div className="body">
        {localBrand !== 5 ? (
          <img
            src={brand?.logo ? brand?.logo : Logo}
            alt="car"
            className="logo"
          />
        ) : null}

        <div className="content">
          {
            brand?.Nom === "NISSAN" ? ( <h1>Choisissez une version</h1>)
            : (<h1>Choisissez {step ? "le véhicule" : "la marque"}</h1>)
          }
          <hr />
          <div className="wrp">
            {step && !brandIsPassed && !isBrandLink ? (
              <button
                className="back-btn"
                onClick={() => {
                  if (step && model === null) {
                    setStep(0);
                  } else if (isBrandParam) {
                    setModel(null);
                    setIsBrandLink(true);
                  } else {
                    setModel(null);
                    setIsBrandLink(false);
                  }
                }}
              >
                Retour
              </button>
            ) : null}

            {loading ? (
              <div className="loader" title="2">
                <svg
                  version="1.1"
                  id="loader-1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="40px"
                  height="40px"
                  viewBox="0 0 50 50"
                  enableBackground="new 0 0 50 50;"
                >
                  <path
                    fill="#000"
                    d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                  >
                    <animateTransform
                      attributeType="xml"
                      attributeName="transform"
                      type="rotate"
                      from="0 25 25"
                      to="360 25 25"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              </div>
            ) : !step && model === null ? (
              brands?.map((brnd, index) =>
                brnd.idMarque != 9 ? (
                  <button
                    className="cart"
                    key={index}
                    onClick={() => {
                      setStep(1);
                      setBrand(brnd);
                    }}
                  >
                    <img src={brnd?.logo} alt={brnd.Nom} className="brand" />
                    <span>{brnd?.Nom}</span>
                  </button>
                ) : null
              )
            ) : step && model === null ? (
              cars?.length ? (
                cars.map((car, index) => (
                  <CarCart
                    setModel={setModel}
                    car={car}
                    key={index}
                    color={color}
                    setIsBrandLink={setIsBrandLink}
                  />
                ))
              ) : (
                <h4>Aucun model pour le moment</h4>
              )
            ) : step && model !== null ? (
              versions?.map((item, index) => (
                <ModelCart
                  setModel={setModel}
                  setStep={setStep}
                  version={item}
                  model={model}
                  key={index}
                  getSegment={getSegment}
                  color={color}
                />
              ))
            ) : (
              <h4>Aucun version pour le moment</h4>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LandingPage;

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  .logo {
    width: min-content;
    margin-top: 1em;
    margin-left: 2em;
    max-width: 110px;
  }
  .content {
    width: 100%;
    max-width: 1300px;
    margin: auto;
  }
  .body {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2;
    position: relative;
    padding: 1em;
  }
  h1 {
    text-align: left;
    color: #fff;
    font-size: 36px;
    letter-spacing: 2px;
    font-weight: 600 !important;
    margin-top: 3em;
  }
  h4 {
    text-align: left;
    color: #777;
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 500 !important;
    white-space: nowrap;
  }
  hr {
    width: 100px;
    height: 6px;
    border: none;
    background-color: #fff;
    margin-top: 0.25em;
    margin-bottom: 2.5em;
  }
  .bg {
    background: ${(props) => (props.color ? props.color : "#222")};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65vh;
    z-index: 0;
  }
  .wrp {
    padding: 4em 3em 2.5em 3em;
    width: 100%;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    background: #fff;
    position: relative;
    border-radius: 20px;
    grid-gap: 3em;
    box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
      rgb(237 239 247 / 47%) 0px 6px 6px;
  }
  .cart {
    display: grid;
    grid-template-rows: 2fr 0.5fr;
    border-radius: 12px;
    border: 2px solid #808080;
    padding: 1em;
    background: transparent;
    height: 170px;
  }
  .brand {
    width: min-content;
    margin: auto;
    max-width: 150px;
    max-height: 100px;
    object-fit: contain;
  }
  span {
    color: #808080;
    font-size: 15px;
    font-weight: 600;
    margin-top: 1em;
    width: 100%;
    text-align: center;
  }
  .model {
    position: fixed;
    background: rgba(163, 163, 163, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  svg {
    fill: rgba(163, 163, 163, 0.61);
  }
  .model-cart {
    position: relative;
    padding: 2em;
    background: #fff;
    border-radius: 12px;
    max-width: 400px;
    height: fit-content;
    h5 {
      color: #5f5f5f;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 1em;
    }
    h6 {
      color: #2145d4;
      font-size: 16px;
      text-align: center;
      font-weight: 600;
    }
    button {
      padding: 7px 32px;
      color: #fff;
      background: #2145d4;
      border-radius: 5px;
      margin-top: 1.5em;
      font-weight: 600;
      font-size: 14px;
      width: 100%;
    }
    img {
      width: 100%;
      margin-bottom: 1.5em;
      margin-top: 0.5em;
    }
    select {
      width: 100%;
      border: none;
      border-radius: 5px;
      margin: 1em 0;
      background: #f2f2f2;
      padding: 7px 5px;
      outline: none;
      margin-top: 3em;
    }
  }
  .close {
    position: absolute;
    top: 1.25em;
    right: 1.2em;
    background: transparent !important;
    width: unset !important;
    margin: unset !important;
    padding: unset !important;
  }
  .price {
    color: #333 !important;
    font-size: 15px !important;
  }
  .back-btn {
    margin-bottom: 1em;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    padding: 4px 8px;
    cursor: pointer;
    margin-top: 1em;
    background-color: ${(props) => (props.color ? props.color : "#222")};
    position: absolute;
    top: 0.7em;
    left: 3.75em;
  }
  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 120px;
    height: 120px;
    svg path,
    svg rect {
      fill: #2d3c85;
    }
  }
`;
