import React, { useState, useEffect } from "react";
import Numeral from "numeral";
import styled from "styled-components";

const SecondStep = ({
  credit,
  setCredit,
  profession,
  setProfession,
  credits,
  model,
  clients,
  color,
}) => {
  const [isActive, setIsActive] = useState(0);
  const infos = [
    {
      name: "Gratuit",
      text: "Gratuit taux 0%",
    },
    {
      name: "Classique",
      text: "Vente à crédit",
    },
    {
      name: "Leasing",
      text: "Location avec option d’achat",
    },
  ];

  const getToolTip = (name) => {
    let text = null;
    infos.map((item) => {
      if (item.name === name) {
        text = item.text;
      }
    });

    if (text !== null) {
      return (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            height="18px"
            fill="#c6c6c6"
            viewBox="0 0 24 24"
            className="infobulle"
          >
            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z" />
          </svg>
          <div className="tooltip">{text}</div>
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    const body = document.getElementById("root");
    body.addEventListener("click", (e) => {
      if (e.target.className !== "select") {
        setIsActive(0);
      }
    });
  }, []);

  return (
    <Container color={color}>
      <h4>Mes infos de simulation: </h4>
      <div className="car-cart">
        <img src={model?.img} alt="car" />
        <h5>{model?.name}</h5>
        <h6>{Numeral(model?.price).format("0,0").replace(",", " ")} DH</h6>
      </div>
      <div className="select-wrp">
        <button
          className="select"
          type="button"
          onClick={() => setIsActive(!isActive)}
        >
          {profession}
          <svg
            width="16"
            height="8"
            viewBox="0 0 20 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="arrow"
          >
            <path
              d="M2 2L10 10L18 2"
              stroke="#DFDFE8"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {isActive ? (
          <div className="options">
            {clients.map((client, index) => (
              <button
                key={index}
                className="option"
                type="button"
                onClick={() => {
                  setProfession(client.clientCategory_name);
                  setIsActive(!isActive);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 24 24"
                  fill="#777"
                >
                  <path d="M20.822 18.096c-3.439-.794-6.641-1.49-5.09-4.418 4.719-8.912 1.251-13.678-3.732-13.678-5.081 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-2.979.688-3.178 2.143-3.178 4.663l.005 1.241h10.483l.704-3h1.615l.704 3h10.483l.005-1.241c.001-2.52-.198-3.975-3.177-4.663zm-8.231 1.904h-1.164l-.91-2h2.994l-.92 2z" />
                </svg>
                {client.clientCategory_name}
              </button>
            ))}
          </div>
        ) : null}
      </div>
      <h4>Choissisez le type de Crédit : </h4>
      <div className="radio-wrp">
        {credits?.map((item, index) => (
          <div className="input-label" key={index}>
            <input
              type="radio"
              id={item.credit_type}
              name="credit"
              value={item.credit_type}
              checked={credit === item.credit_type}
              onChange={() => setCredit(item.credit_type)}
            />
            <label htmlFor={item.credit_type}>{item.credit_type}</label>
            {getToolTip(item.credit_type)}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default SecondStep;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  h4 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 3em;
    margin-bottom: 2em;
    width: 100%;
    color: ${(props) => (props.color ? props.color : "#222")};
  }
  label {
    font-size: 14px;
    font-weight: 500;
  }
  .infobulle {
    margin-left: 0.75em;
    margin-right: 0;
    color: ${(props) => (props.color ? props.color : "#222")};
    font-weight: 600;
    cursor: context-menu;
    font-size: 14px;
  }
  .radio-wrp {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .input-label {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0.5em 0;
    margin-right: 2em;
  }
  input[type="radio"] {
    margin-right: 0.4em;
  }
  .infobulle:hover ~ .tooltip {
    display: block;
  }
  .tooltip {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    max-width: 95px;
    font-family: "Helvetica", sans-serif;
    padding: 6px;
    z-index: 4;
    position: absolute;
    right: -6em;
    top: -4em;
    display: none;
    &:before {
      border-top: 7px solid rgba(0, 0, 0, 0.85);
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      content: "";
      display: block;
      left: 8px;
      bottom: -7px;
      position: absolute;
    }
  }
  .car-cart {
    border: 2px solid ${(props) => (props.color ? props.color : "#222")};
    border-radius: 7px;
    padding: 1em;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    margin: auto;
    margin-bottom: 2em;
  }
  h5 {
    color: ${(props) => (props.color ? props.color : "#222")};
    font-size: 16px;
    font-weight: 600;
    margin-top: 0.5em;
  }
  h6 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 0.5em;
  }
  img {
    max-width: calc(260px - 2em);
    max-height: 150px;
  }
  .select-wrp {
    position: relative;
  }
  .select {
    padding: 10px;
    border-radius: 7px;
    border: 2px solid rgba(0, 0, 0, 0.05);
    min-width: 310px;
    width: 100%;
    outline: none;
    max-width: 400px;
    color: black !important;
    font-size: 15px !important;
    font-weight: 600;
  }
  .options {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;
    border-radius: 8px;
    max-width: 400px;
    position: absolute;
    top: 2.9em;
    left: 0;
    z-index: 10;
  }
  .option {
    width: 100%;
    padding: 7px 10px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    color: #777;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
  }
  .option:last-child {
    border-bottom: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .option:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  svg {
    margin-right: 1.5em;
  }
  .select {
    color: #a5a5a9;
    text-align: left;
    background-color: #fbfbfb;
    border: 1px solid #eee;
    margin-bottom: 0.5em;
    justify-content: space-between;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
  .arrow {
    margin-right: 0em;
  }
  @media only screen and (max-width: 1200px) {
    .car-cart {
      display: flex;
    }
  }
`;
