import React, { useState } from "react";
import styled from "styled-components";
import { PDFDocument, rgb } from "pdf-lib";
import download from "downloadjs";
import fontkit from "@pdf-lib/fontkit";
import Numeral from "numeral";
import moment from "moment";
import Nissan from "../assets/nissan.png";
import N2 from "../assets/Nissan_logo.png";
import "moment/locale/fr";

const NavigationButtons = ({
  step,
  setStep,
  dataFilled,
  credit,
  results,
  price,
  duration,
  apport,
  clientInfos,
  model,
  setDataFilled,
  brand,
  color,
}) => {
  const [loading, setLoading] = useState(0);
  console.log(brand);
  const Create = async () => {
    setLoading(1);
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([595.28, 841.89]);

    const url = "https://pdf-lib.js.org/assets/ubuntu/Ubuntu-B.ttf";
    const fontBytes = await fetch(url).then((res) => res.arrayBuffer());

    pdfDoc.registerFontkit(fontkit);
    const boldFont = await pdfDoc.embedFont(fontBytes);
    const logoUrl = brand?.Nom === "NISSAN" ? Nissan:  "https://i.ibb.co/XX56FqB/autohall.png";
    const logoUrl2 = brand?.Nom === "NISSAN" ? N2 : "https://i.ibb.co/BCxnLms/autohall-credit.png";
    const logoImageBytes = await fetch(logoUrl).then((res) =>
      res.arrayBuffer()
    );

      const logoImageBytes2 = await fetch(logoUrl2).then((res) =>
      res.arrayBuffer()
    );

    const logo = await pdfDoc.embedPng(logoImageBytes);
    const logo2 = await pdfDoc.embedPng(logoImageBytes2);
    const logoDims2 = logo2.scale(1);
    const logoDims = logo.scale(0.35);
  

    moment.locale("fr");
    if(brand?.Nom !== "NISSAN"){
      const date = moment(new Date()).format("LL").toUpperCase();
      page.drawImage(logo, {
        x: 40,
        y: page.getHeight() - logoDims.width + 10,
        width: logoDims.width + 20,
        height: logoDims.width - 40,
      });
      // Credit
      page.drawImage(logo2, {
        x: 430,
        y: page.getHeight() - logoDims.width + 10,
        width: logoDims.width + 40,
        height: logoDims.width - 40,
      });
    }else{
      page.drawImage(logo, {
        x: 20,
        y:  page.getHeight() - logoDims.height -30 ,
        width:  logoDims.width  ,
        height:   logoDims.height  
      });
      page.drawImage(logo2, {
        x: 480,
        y: page.getHeight() - logoDims.width +170,
        width: logoDims.width -190 ,
        height: logoDims.height +30,
      });
    }

    // page.drawText(`Offre ${credit}`, {
    //   x: 412,
    //   y: page.getHeight() - 66,
    //   size: 17,
    //   font: boldFont,
    // });
    // page.drawText(`Etablie le : ${date}`, {
    //   x: 412,
    //   y: page.getHeight() - 83,
    //   size: 11,
    //   font: boldFont,
    //   color: rgb(0.5, 0.5, 0.5),
    // });
    page.drawText("Mr/Mme " + clientInfos.name, { x: 40, y: 650, size: 11 });
    page.drawText(
      "Nous sommes heureux de vous faire cette offre commerciale.",
      { x: 40, y: 635, size: 11 }
    );
    page.drawText("DESCRIPTION DU VEHICULE", {
      x: 200,
      y: 570,
      size: 14,
      font: boldFont,
    });
    console.log(brand);
    page.drawText(`Marque: ${brand.Nom}`, {
      x: 40,
      y: 530,
      size: 12,
    });
    page.drawText(`Modèle: ${model.Nom}`, {
      x: 40,
      y: 505,
      size: 12,
    });
    page.drawText(`Version: ${model.name.replace(model.Nom + " ", "")}`, {
      x: 40,
      y: 480,
      size: 12,
    });
    page.drawText("OFFRE DE FINANCEMENT", {
      x: 210,
      y: 430,
      size: 14,
      font: boldFont,
    });
    //* *******************Table********************//
    //* *******************Row 0********************//
    page.drawRectangle({
      x: 40,
      y: 360,
      width: 510,
      height: 25,
      color: rgb(0.55, 0.74, 1),
    });
    page.drawText("Montant du bien TTC :", {
      x: 50,
      y: 367,
      size: 12,
    });
    page.drawText(`${Numeral(price).format("0,0").replace(",", " ")} DH`, {
      x: 480,
      y: 367,
      size: 12,
    });
    //* *******************Row 1********************//
    page.drawRectangle({
      x: 40,
      y: 335,
      width: 510,
      height: 25,
      color: rgb(1, 1, 1),
    });
    page.drawText("Type de crédit :", {
      x: 50,
      y: 342,
      size: 12,
    });
    page.drawText(credit, {
      x: 480,
      y: 342,
      size: 12,
    });
    //* *******************Row 2********************//
    page.drawRectangle({
      x: 40,
      y: 310,
      width: 510,
      height: 25,
      color: rgb(0.55, 0.74, 1),
    });
    page.drawText("Apport :", {
      x: 50,
      y: 317,
      size: 12,
    });
    page.drawText(
      `${Numeral(apport).format("0,0").replace(",", " ")} ${
        results.apportType
      }`,
      {
        x: 480,
        y: 317,
        size: 12,
      }
    );
    //* *******************Row 3********************//
    page.drawRectangle({
      x: 40,
      y: 285,
      width: 510,
      height: 25,
      color: rgb(1, 1, 1),
    });
    page.drawText("Apport yc frais de dossier :", {
      x: 50,
      y: 292,
      size: 12,
    });
    page.drawText(
      `${Numeral(results?.apportFolderFees)
        .format("0,0")
        .replace(",", " ")} DH`,
      {
        x: 480,
        y: 292,
        size: 12,
      }
    );
    //* *******************Row 5********************//
    page.drawRectangle({
      x: 40,
      y: 260,
      width: 510,
      height: 25,
      color: rgb(0.55, 0.74, 1),
    });
    page.drawText("Durée (Mois) :", {
      x: 50,
      y: 267,
      size: 12,
    });
    page.drawText(`${duration} mois`, {
      x: 480,
      y: 267,
      size: 12,
    });
    //* *******************Row 6********************//
    page.drawRectangle({
      x: 40,
      y: 235,
      width: 510,
      height: 25,
      color: rgb(1, 1, 1),
    });
    page.drawText("Mensualité :", {
      x: 50,
      y: 242,
      size: 12,
    });
    page.drawText(
      `${Numeral(results?.mensualite).format("0,0").replace(",", " ")} DH`,
      {
        x: 480,
        y: 242,
        size: 12,
      }
    );
    //* *******************Row 7********************//
    page.drawRectangle({
      x: 40,
      y: 210,
      width: 510,
      height: 25,
      color: rgb(0.55, 0.74, 1),
    });
    page.drawText("Assurance vie :", {
      x: 50,
      y: 217,
      size: 12,
    });
    page.drawText(
      `${Numeral(results?.adi).format("0,0").replace(",", " ")} DH`,
      {
        x: 480,
        y: 217,
        size: 12,
      }
    );
    //* *******************Row 8********************//
    if (credit === "Classique") {
      page.drawRectangle({
        x: 40,
        y: 185,
        width: 510,
        height: 25,
        color: rgb(1, 1, 1),
      });
      page.drawText("Autre assurance :", {
        x: 50,
        y: 192,
        size: 12,
      });
      page.drawText(
        `${Numeral(results?.assurance_vidp)
          .format("0,0")
          .replace(",", " ")} DH`,
        {
          x: 482,
          y: 192,
          size: 12,
        }
      );
    }
    //* *******************Row 9********************//
    // page.drawRectangle({
    //   x: 40,
    //   y: 203,
    //   width: 510,
    //   height: 25,
    //   color: rgb(0.55, 0.74, 1),
    // });
    // page.drawText("Dernière échéance :", {
    //   x: 50,
    //   y: 210,
    //   size: 12,
    // });
    // page.drawText("-", {
    //   x: 480,
    //   y: 210,
    //   size: 12,
    // });
    page.drawText(
      "Offre non contractuelle, soumise à conditions. Sous réserve d’acceptation de votre dossier de crédit. ",
      {
        x: 40,
        y: 155,
        size: 9,
        color: rgb(0.6, 0.6, 0.6),
        font: boldFont,
      }
    );
    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, "simulation.pdf", "application/pdf");
    setLoading(0);
  };

  return (
    <Container color={color}>
      {step > 1 ? (
        <button
          className="btn back-btn"
          onClick={() => {
            if (step === 4) {
              setDataFilled(false);
            }
            setStep(step - 1);
          }}
        >
          Précédent
        </button>
      ) : null}
      <div className="btns-row">
        {step === 4 && dataFilled ? (
          <button
            className="btn back-btn btn-download"
            onClick={() =>
              Create(
                credit,
                results,
                price,
                duration,
                apport,
                clientInfos,
                model
              )
            }
          >
            {loading ? "Télécharger..." : "Télécharger"}
          </button>
        ) : null}

        {step === 4 ? null : (
          <button
            className="btn"
            type="submit"
            onClick={() => setStep(step + 1)}
          >
            Suivant
          </button>
        )}
      </div>
    </Container>
  );
};

export default NavigationButtons;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    padding: 13px 30px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    background: ${(props) => (props.color ? props.color : "#222")};
    margin-top: 4em;
  }
  .back-btn {
    background: #f0f5fd;
    color: #666;
    margin-right: 1em;
  }
  /* .btn-download {
    display: none;
  } */
  @media only screen and (max-width: 576px) {
    button {
      padding: 8px 15px;
      font-size: 12px;
      font-weight: 500;
    }
    .back-btn {
      margin-right: 0.5em;
    }
  }
`;
