import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Numeral from "numeral";
import LandingPage from "./components/LandingPage";
import SecondStep from "./components/SecondStep";
import ThirdStep from "./components/ThirdStep";
import FourthStep from "./components/FourthStep";
import Steps from "./components/Steps";
import NavigationButtons from "./components/NavigationButtons";
import Logo from "./assets/autohall.png";
import Popup from "./components/Popup";
import  Plugin from "./Plugins/helios-iframe-resizer";
import CalculateCredit from "./utils/CalculateCredit";

import {
  GetEligibility,
  GetClientCategories,
  GetBrands,
  GetParams,
  GetScenarios,
  AddSimulation,
  GetCars,
  GetVersions,
  GetModel,
  GetVersion,
  GetLocalBrands,
  GetCities,
} from "./utils/Models";
import Conditions from "./components/Conditions";

const App = () => {
  const [isActive, setIsActive] = useState(0);
  const [cars, setCars] = useState([]);
  const [action, setAction] = useState(0);
  const [step, setStep] = useState(0);
  const [brand, setBrand] = useState(null);
  const [model, setModel] = useState(null);
  const [versions, setVersions] = useState(null);
  const [credits, setCredits] = useState([]);
  const [credit, setCredit] = useState(credits[0]);
  const [clients, setClients] = useState([]);
  const [clientInfos, setClientInfos] = useState(null);
  const [profession, setProfession] = useState(null);
  const [duration, setDuration] = useState(36);
  const [apport, setApport] = useState(30);
  const [results, setResults] = useState({});
  const [dataFilled, setDataFilled] = useState(false);
  const [slidersData, setSlidersData] = useState({});
  const [params, setParams] = useState({});
  const [brands, setBrands] = useState([]);
  const [localBrands, setLocalBrands] = useState([]);
  const [localBrand, setLocalBrand] = useState(null);
  const [brandIsPassed, setBrandIsPassed] = useState(false);
  const [isBrandLink, setIsBrandLink] = useState(false);
  const [isBrandParam, setIsBrandParam] = useState(false);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(0);
  const [color, setColor] = useState(0);

 useEffect(() => {
  Plugin();
 }, []);

  const calculate = async () => {
    const scenario = await GetScenarios(
      localBrand,
      credit,
      model.segment,
      profession,
      apport,
      duration
    );
    const data = { ...scenario, ...params };
    const result = CalculateCredit(model.price, duration, apport, credit, data);
    setResults(result);
  };

  const sendSimulation = async (infos) => {
    setClientInfos(infos);
    await AddSimulation(
      infos,
      profession,
      credit,
      duration,
      apport,
      model.price,
      results,
      localBrand,
      model.name
    );
  };

  const getSegment = (seg) => {
    switch (seg) {
      case "1":
        return "Voiture particuliére (de tourisme)";
      case "2":
        return "Véhicule utilitaire leger";
      case "3":
        return "Utilitaire poids lourds";
      case "4":
        return "Voiture d'occasion";
      default:
        return "Voiture particuliére (de tourisme)";
    }
  };

  useEffect(async () => {
    console.log("step", step);
    console.log("is brand passed", brandIsPassed);
    if (profession && model?.segment) {
      const res = await GetEligibility(model.segment, profession);
      setCredits(res);
      setCredit(res[0].credit_type);
    }
  }, [profession, model]);

  useEffect(async () => {
    if (credit) {
      setDuration(36);
      setApport(30);
      const res = await GetParams(credit, model.segment, profession);
      setParams(res);

      if (credit === "Gratuit") {
        setSlidersData({
          apportMax: 60,
          apportMin: 10,
          apportType: "%",
          apportStep: 10,
          durationMax: res.durationMax,
          durationMin: 24,
          durationStep: 12,
        });
      } else {
        setSlidersData({
          apportMax: model.price * 0.99,
          apportMin: 0,
          apportType: "DH",
          apportStep: 100,
          durationMax: res.durationMax,
          durationMin: 1,
          durationStep: 1,
        });
      }
    }
  }, [credit]);

  useEffect(() => {
    if (step === 4) {
      calculate();
    }

    if (!step) {
      setBrand(null);
    }
  }, [step]);

  useEffect(async () => {
    setLoading(1);
    const res1 = await GetLocalBrands();
    setLocalBrands(res1);
    const res2 = await GetClientCategories();
    setClients(res2);
    setProfession(res2[0].clientCategory_name);
    const res3 = await GetBrands();
    const res4 = await GetCities();
    setCities(res4);

    if (typeof window != "undefined") {
      const url = new URL(window.location.href);
      const idParam = url.searchParams.get("id");
      const brandParam = url.searchParams.get("brand");
      let brandIsAvailable = false;
      let idIsAvailable = false;

      if (brandParam !== null) {
        res3.map(async (item) => {
          if (item.Nom.toLowerCase() === brandParam.toLowerCase()) {
            setBrandIsPassed(true);
            setBrand(item);
            setStep(1);
            setIsBrandParam(true);
            brandIsAvailable = true;

            if (idParam === null) {
              const res = await GetCars(item.idMarque);
              setCars(res);
              setLoading(0);
            }
          }
        });
        res1.map((item) => {
          if (item.brand_name.toLowerCase() === brandParam.toLowerCase()) {
            setLocalBrand(item.brand_id);
            setColor(item.brand_color);
          }
        });
        if (brandIsAvailable) {
          setStep(1);
          setBrandIsPassed(false);
          setIsBrandLink(true);
        }
      } else {
        setBrands(res3);
        setLoading(0);
      }

      if (idParam !== null) {
        const selectedVersion = await GetVersion(idParam);
        const selectedModel = await GetModel(selectedVersion.Modele_idModele);

        setModel({
          ...selectedModel,
          id: selectedVersion.idVersion,
          name: selectedModel.Nom + " " + selectedVersion.Nom,
          img: selectedVersion.image || selectedModel.image,
          segment: getSegment(selectedModel.Segment_idSegment),
          price: selectedVersion.prix || selectedModel.prix,
        });
        idIsAvailable = true;

        if (idIsAvailable) {
          setStep(2);
        }
      }
    }
  }, []);

  useEffect(async () => {
    if (brand !== null && !brandIsPassed) {
      setLoading(1);
      const res = await GetCars(brand.idMarque);
      setCars(res);
      localBrands.map((item) => {
        if (item.brand_name.toLowerCase() === brand.Nom.toLowerCase()) {
          setLocalBrand(item.brand_id);
          setColor(item.brand_color);
        }
      });
      setLoading(0);
    }
  }, [brand]);

  useEffect(async () => {
    if (model !== null && !model.id) {
      setLoading(1);
      const res = await GetVersions(model.idModele);
      setVersions(res);
      setLoading(0);
    }
  }, [model]);

  const renderContent = () => {
    switch (step) {
      case 2:
        return (
          <SecondStep
            credit={credit}
            setCredit={setCredit}
            profession={profession}
            setProfession={setProfession}
            setCredits={setCredits}
            credits={credits}
            setApport={setApport}
            setDuration={setDuration}
            model={model}
            clients={clients}
            color={color}
          />
        );
      case 3:
        return (
          <ThirdStep
            duration={duration}
            setDuration={setDuration}
            apport={apport}
            setApport={setApport}
            maxPrice={model.price}
            credit={credit}
            profession={profession}
            segment={model.segment}
            model={model}
            data={slidersData}
            color={color}
          />
        );
      case 4:
        return (
          <FourthStep
            model={model}
            results={results}
            price={model.price}
            duration={duration}
            apport={apport}
            credit={credit}
            setDataFilled={setDataFilled}
            dataFilled={dataFilled}
            setClientInfos={setClientInfos}
            cities={cities}
            send={sendSimulation}
            setIsActive={setIsActive}
            color={color}
          />
        );
      default:
    }
  };

  if (step > 1) {
    return (
      <Container step={step} color={brand.brand_color}>
        <div
          className="bg"
          style={brand ? { background: brand.brand_color } : {}}
        />
        {localBrand !== 5 ? (
          <img
            src={brand?.logo ? brand?.logo : Logo}
            alt="car"
            className="logo"
          />
        ) : null}
        <div className={step === 4 ? "wrp wrp-img wrp-hide" : "wrp wrp-img"}>
          <h1 className="heading heading-1">{model?.name}</h1>
          {model.price ? (
            <h1 className="heading heading-2">
              {Numeral(model?.price).format("0,0").replace(",", " ")} DH
            </h1>
          ) : null}
          <img
            src={model?.img || "https://i.ibb.co/7bcSp6d/ford-38a163f9.webp"}
            alt="car"
            className="car"
          />
        </div>
        <div className="wrp wrp-form">
          <div className="forms-wrp">
            <Steps step={step} color={color} />
            {renderContent()}
            {step > 1 ? (
              <NavigationButtons
                step={step}
                setStep={setStep}
                dataFilled={dataFilled}
                credit={credit}
                results={results}
                price={model.price}
                duration={duration}
                apport={apport}
                clientInfos={clientInfos}
                model={model}
                setDataFilled={setDataFilled}
                brand={brand}
                color={color}
              />
            ) : null}
          </div>
        </div>
        {isActive ? <Conditions setIsActive={setIsActive} /> : null}
        {action ? (
          <Popup
            message="La simulation a été envoyée avec succès"
            setAction={setAction}
            setStep={setStep}
            setModel={setModel}
          />
        ) : null}
      </Container>
    );
  }

  return (
    <LandingPage
      brands={brands}
      setBrand={setBrand}
      brand={brand}
      setStep={setStep}
      step={step}
      setModel={setModel}
      model={model}
      cars={cars}
      versions={versions}
      brandIsPassed={brandIsPassed}
      getSegment={getSegment}
      loading={loading}
      color={color}
      localBrand={localBrand}
      isBrandLink={isBrandLink}
      setIsBrandLink={setIsBrandLink}
      isBrandParam={isBrandParam}
    />
  );
};

export default App;

const Container = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.step === 4 ? "1fr" : "auto 500px"};
  grid-gap: 4em;
  min-height: 100vh;
  max-width: 1800px;
  padding: 0 5em;
  margin: auto;
  .wrp {
    padding: 5em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    z-index: 2;
  }
  .bg {
    background: ${(props) => (props.color ? props.color : "#222")};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    z-index: 0;
  }
  .car {
    margin-top: 2em;
    max-width: 900px;
    max-height: 400px !important;
    width: 100%;
    object-fit: cover;
  }
  .heading {
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 48px;
    letter-spacing: 2px;
    font-weight: 600 !important;
    z-index: 2;
    margin-bottom: 0 !important;
  }
  .heading-1 {
    margin-top: 2.25em;
  }
  .heading-2 {
    font-size: 24px;
    letter-spacing: 2px;
    margin-top: 0 !important;
  }
  .logo {
    position: absolute;
    left: 2em;
    top: 2em;
    max-width: 150px;
    z-index: 2;
  }
  .forms-wrp {
    margin: 5em 0;
    position: relative;
    background: #fff;
    border-radius: 20px;
    padding: 1.5em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
      rgb(237 239 247 / 47%) 0px 6px 6px;
    width: 100%;
    min-height: 400px;
    max-width: ${(props) => (props.step === 4 ? "1300px" : "600px")};
  }
  .wrp-img {
    align-items: flex-start;
  }
  .wrp-hide {
    display: none;
  }
  @media only screen and (max-width: 1450px) {
    grid-gap: 2em;
    padding: 0 2em;
    .heading {
      margin-top: 3em;
      font-size: 40px;
    }
    .heading-2 {
      font-size: 22px;
    }
  }
  @media only screen and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.7em;
    .wrp-img {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    .forms-wrp {
      padding: 1.5em 1em;
    }
    .carts {
      padding: 2em 0.5em;
    }
    .carts-wrp {
      padding: 2em 0.4em 0 0.4em;
    }
  }
`;
