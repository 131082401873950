import React from "react";
import Numeral from "numeral";
import styled from "styled-components";
import Slider from "rc-slider";

const ThirdStep = ({
  duration,
  setDuration,
  apport,
  setApport,
  data,
  maxPrice,
  model,
  color,
}) => {
  return (
    <Container color={color}>
      <div className="car-cart">
        <img src={model?.img} alt="car" />
        <h5>{model?.name}</h5>
        <h6>{Numeral(model?.price).format("0,0").replace(",", " ")} DH</h6>
      </div>
      <div className="row-info">
        <h5>Montant du bien</h5>
        <h5 className="selected-data">
          {Numeral(maxPrice).format("0,0").replace(",", " ")} DH
        </h5>
      </div>
      <div className="row-info">
        <h5>Apport</h5>
        <h5 className="selected-data">
          {apport} {data?.apportType}
        </h5>
      </div>
      <Slider
        defaultValue={apport}
        min={data?.apportMin}
        max={data?.apportMax}
        step={data?.apportStep}
        dots={data?.apportType === "%"}
        onChange={(e) => setApport(e)}
        trackStyle={{ backgroundColor: color, height: 8 }}
        handleStyle={{
          borderColor: color,
          height: 18,
          width: 18,
          marginLeft: -2,
          marginTop: -5,
          backgroundColor: color,
        }}
        railStyle={{ backgroundColor: "#f2f2f2", height: 8 }}
        dotStyle={{
          bottom: -5,
          height: 10,
          width: 8,
          borderRadius: 3,
        }}
        activeDotStyle={{
          borderColor: color,
        }}
      />
      <div className="row-info">
        <h5>Durée du Crédit</h5>
        <h5 className="selected-data">{duration} Mois</h5>
      </div>
      <Slider
        defaultValue={duration}
        min={data?.durationMin}
        max={data?.durationMax}
        step={data?.durationStep}
        dots={data?.durationStep === 12}
        onChange={(e) => setDuration(e)}
        trackStyle={{ backgroundColor: color, height: 8 }}
        handleStyle={{
          borderColor: color,
          height: 18,
          width: 18,
          marginLeft: -2,
          marginTop: -5,
          backgroundColor: color,
        }}
        railStyle={{ backgroundColor: "#f2f2f2", height: 8 }}
        dotStyle={{
          bottom: -5,
          height: 10,
          width: 8,
          borderRadius: 3,
        }}
        activeDotStyle={{
          borderColor: color,
        }}
      />
    </Container>
  );
};

export default ThirdStep;

const Container = styled.div`
  width: 100%;
  margin: 2em 0;
  padding: 0 1em;
  .row-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 2.25em 0 1em 0;
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
    justify-content: left;
  }
  .selected-data {
    color: ${(props) => (props.color ? props.color : "#222")};
    font-size: 15px;
  }
  input {
    cursor: pointer;
  }
  label {
    font-size: 14px;
    margin-right: 1.5em;
    font-weight: 500;
  }
  .checkbox-wrp {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 2em 0 0 0;
  }
  .check-label {
    font-size: 13px;
    font-weight: 400;
    color: #777;
    margin-left: 1em;
  }
  .car-cart {
    border: 2px solid ${(props) => (props.color ? props.color : "#222")};
    border-radius: 7px;
    padding: 1em;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    margin: auto;
  }
  h5 {
    color: ${(props) => (props.color ? props.color : "#222")};
    font-size: 16px;
    font-weight: 600;
    margin-top: 0.5em;
  }
  h6 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 0.5em;
  }
  img {
    max-width: calc(260px - 2em);
    max-height: 150px;
  }

  @media only screen and (max-width: 1200px) {
    .car-cart {
      display: flex;
    }
  }
`;
