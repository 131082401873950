import React from "react";
import styled from "styled-components";
import Numeral from "numeral";

const ModelCart = ({
  setModel,
  model,
  version,
  setStep,
  getSegment,
  color,
}) => {
  return (
    <Container color={color}>
      <div className="top-side side">
        <img src={version.image || model.image} alt="item" />
        <h5>{model.Nom + " " + version.Nom}</h5>
        <h6>
          {Numeral(version.prix || model.prix)
            .format("0,0")
            .replace(",", " ")}{" "}
          DH
        </h6>
      </div>
      <div className="bottom-side side">
        <h5>{model.Nom + " " + version.Nom}</h5>
        <h6>
          {Numeral(version.prix || model.prix)
            .format("0,0")
            .replace(",", " ")}{" "}
          DH
        </h6>
        <button
          onClick={() => {
            setModel({
              ...model,
              id: version.idVersion,
              name: model.Nom + " " + version.Nom,
              img: version.image || model.image,
              segment: getSegment(model.Segment_idSegment),
              price: version.prix || model.prix,
            });
            setStep(2);
          }}
        >
          Choisir
        </button>
      </div>
    </Container>
  );
};

export default ModelCart;

const Container = styled.div`
  background-color: white;
  border-radius: 12px;
  border: 2px solid ${(props) => (props.color ? props.color : "#222")};
  position: relative;
  &:hover {
    .bottom-side {
      opacity: 1;
    }
  }
  .side {
    display: grid;
    grid-template-rows: 1.5fr 0.25fr;
    padding: 1em 0.5em;
    width: 100%;
    height: 100%;
    transition: all 1s;
  }
  img {
    width: 100%;
    margin: auto;
    height: 150px;
    object-fit: contain;
  }
  h5 {
    color: ${(props) => (props.color ? props.color : "#222")};
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.5em;
  }
  h6 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: right;
  }
  .bottom-side {
    grid-template-rows: 1.5fr 0.25fr 0.25fr;
    background: ${(props) => (props.color ? props.color : "#222")};
    align-items: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.4s;
    h5,
    h6 {
      color: #fff;
      margin: 5px 0;
    }
  }
  button {
    padding: 7px 32px;
    color: ${(props) => (props.color ? props.color : "#222")};
    background: #fff;
    border-radius: 100px;
    margin-top: 1.5em;
    font-weight: 600;
    font-size: 14px;
  }
`;
