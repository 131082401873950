import React from "react";
import styled from "styled-components";
import Numeral from "numeral";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "./CustomInput";

const FourthStep = ({
  results,
  price,
  duration,
  apport,
  credit,
  model,
  setDataFilled,
  dataFilled,
  cities,
  send,
  setIsActive,
  color,
}) => {
  return (
    <Container color={color}>
      {!dataFilled ? (
        <>
          <h3>Informations personnelles</h3>
          <Formik
            initialValues={{
              email: "",
              phone: "",
              name: "",
              city: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Email invalid")
                .required("Champs Obligatoire"),
              name: Yup.string().required("Champs Obligatoire"),
              phone: Yup.string()
                .matches(/^[0-9]*$/, "Numéro de téléphone invalide")
                .min(10, "Numéro de téléphone invalide")
                .max(10, "Numéro de téléphone invalide")
                .required("Champ Obligatoire"),
              city: Yup.string().required("Champs Obligatoire"),
            })}
            onSubmit={async (data, { setSubmitting }) => {
              setSubmitting(true);
              await setDataFilled(true);
              await send(data);
              setSubmitting(false);
            }}
          >
            {({ values, errors, handleChange, handleBlur, touched }) => (
              <Form className="form">
                <div className="inputs">
                  <CustomInput
                    margin="0 0.5em"
                    label="Nom Complet"
                    name="name"
                    id="name"
                    type="text"
                    placeholder=""
                  />
                  <CustomInput
                    margin="0 0.5em"
                    label="Email"
                    name="email"
                    id="email"
                    type="text"
                    placeholder=""
                  />
                  <CustomInput
                    margin="0 0.5em"
                    label="Numéro de téléphone"
                    name="phone"
                    id="phone"
                    type="text"
                    placeholder=""
                  />
                  <div className="select-wrp">
                    <label>Ville</label>
                    <select
                      name="city"
                      valuee={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Choisir...</option>
                      {cities.map((item, index) => (
                        <option value={item.city_name} key={index}>
                          {item.city_name}
                        </option>
                      ))}
                    </select>
                    {touched.city && errors.city && (
                      <div className="error">{errors.city}</div>
                    )}
                  </div>
                </div>
                <button className="submit" type="submit">
                  Afficher Simulation
                </button>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <h3>Détail de la simulation Crédit</h3>
          <div className="content">
            <div className="wrapper">
              <div className="col">
                <div className="row">
                  <p className="label">Montant du bien TTC</p>
                  <p className="data">
                    {Numeral(price).format("0,0").replace(",", " ")} DH
                  </p>
                </div>
                <div className="row">
                  <p className="label">Type de crédit</p>
                  <p className="data">{credit}</p>
                </div>
                <div className="row">
                  <p className="label">Apport</p>
                  <p className="data">
                    {Numeral(apport).format("0,0").replace(",", " ")}{" "}
                    {results.apportType}
                  </p>
                </div>
                <div className="row">
                  <p className="label">Apport yc frais de dossier</p>
                  <p className="data">
                    {Numeral(results?.apportFolderFees)
                      .format("0,0")
                      .replace(",", " ")}{" "}
                    DH
                  </p>
                </div>
                <div className="row">
                  <p className="label">Mensualité</p>
                  <p className="data">
                    {Numeral(results?.mensualite)
                      .format("0,0")
                      .replace(",", " ")}{" "}
                    DH
                  </p>
                </div>
                <div className="row">
                  <p className="label">Durée (Mois)</p>
                  <p className="data">{duration} Mois</p>
                </div>
                <div className="row">
                  <p className="label">Assurance vie</p>
                  <p className="data">
                    {Numeral(results?.adi).format("0,0").replace(",", " ")} DH
                  </p>
                </div>
                {credit === "Classique" ? (
                  <div className="row">
                    <p className="label">Autre assurance</p>
                    <p className="data">
                      {Numeral(results?.assurance_vidp)
                        .format("0,0")
                        .replace(",", " ")}{" "}
                      DH
                    </p>
                  </div>
                ) : null}
              </div>

              <p className="desc">
                Offre non contractuelle, soumise à{" "}
                <button
                  className="blue"
                  type="button"
                  onClick={() => setIsActive(1)}
                >
                  conditions.
                </button>{" "}
                Sous réserve d’acceptation de votre dossier de crédit.
              </p>
            </div>
            <div className="wrapper col-2">
              <div className="car-cart">
                <img src={model?.img} alt="car" />
                <h5>{model?.name}</h5>
                <h6>
                  {Numeral(model?.price).format("0,0").replace(",", " ")} DH
                </h6>
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default FourthStep;

const Container = styled.div`
  width: 100%;
  h3 {
    margin: 3em 0 2em 0;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    text-align: left;
    color: ${(props) => (props.color ? props.color : "#222")};
  }
  .content {
    padding: 0 1em;
    width: 100%;
    display: grid;
    grid-template-columns: auto 260px;
  }
  .wrapper {
    width: 100%;
  }
  .col {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .label {
    font-size: 16px;
    font-weight: 600;
    margin: 0.6em 0;
    color: #5f5f5f;
  }
  .credit-label {
    font-size: 16px;
    font-weight: 600;
    margin-right: 1em;
    color: #5f5f5f;
  }
  .data {
    margin: 0.6em 0;
    font-size: 16px;
    font-weight: 600;
    color: #c0b7b8;
  }
  .row {
    display: grid;
    grid-template-columns: 1.25fr 1fr;
  }
  img {
    max-width: calc(260px - 2em);
    max-height: 150px;
  }
  .checkbox-wrp {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    width: 100%;
  }
  .car-cart {
    border: 2px solid ${(props) => (props.color ? props.color : "#222")};
    border-radius: 7px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .check-label {
    font-size: 13px;
    font-weight: 600;
    color: #777;
    margin-left: 1em;
  }
  .credit-type {
    border-radius: 7px;
    padding: 8px 30px;
    font-size: 16px;
    font-weight: 600;
    background: #e6e7ec;
    margin-right: 1em;
    letter-spacing: 0.5px;
    width: fit-content;
    width: 150px;
  }
  .desc {
    font-size: 12px;
    font-weight: 600;
    color: rgb(33, 69, 212);
    line-height: 1.6em;
    margin-bottom: 1em;
    margin-top: 2em;
  }
  h5 {
    color: ${(props) => (props.color ? props.color : "#222")};
    font-size: 16px;
    font-weight: 600;
    margin-top: 0.5em;
  }
  h6 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 0.5em;
  }
  .column-2 {
    display: flex;
    flex-direction: column;
  }
  .btn-flex {
    display: flex;
    align-items: center;
    margin: 2em 0;
    width: 100%;
  }
  .result {
    border: 2px solid ${(props) => (props.color ? props.color : "#222")};
    border-radius: 7px;
    padding: 0em 1em;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    .data {
      color: ${(props) => (props.color ? props.color : "#222")};
      margin-left: 2em;
    }
  }
  .inputs {
    max-width: 800px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-column-gap: 2em;
  }
  input[type="text"] {
    width: 100%;
  }
  .submit {
    padding: 13px 30px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    background: ${(props) => (props.color ? props.color : "#222")};
    margin-top: 2em;
    position: absolute;
    bottom: 1.7em;
    right: 2em;
  }
  .select-wrp {
    display: flex;
    flex-direction: column;
    select {
      padding: 10px;
      font-size: 14px;
      border-radius: 7px;
      border: 1px solid rgba(0, 0, 0, 0.07);
      min-width: ${(props) => (props.small ? "170px" : "260px")};
      margin-bottom: 0.35em;
      max-width: ${(props) => (props.small ? "170px" : "unset")};
      outline: none;
    }
    label {
      font-size: 14px;
      font-weight: 600;
      color: #4d4d4d;
      margin-bottom: 0.35em;
      margin-top: 1.5em;
    }
    .error {
      font-size: 11px;
      color: red;
      font-weight: 600;
      margin-top: 0.1em;
      margin-left: 0.2em;
    }
  }
  .blue {
    font-weight: 600;
    background: transparent;
    text-decoration: underline;
    color: rgb(33, 69, 212);
    font-size: 12px;
  }
  @media only screen and (max-width: 1100px) {
    .content {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
    .col-2 {
      grid-row-start: 1;
      grid-row-end: 2;
      margin: auto;
      max-width: 260px;
      margin-bottom: 2em;
    }
  }
  @media only screen and (max-width: 768px) {
    .content {
      padding: 0 0.5em;
    }
    .col {
      display: grid;
      grid-template-columns: 1fr;
    }
    .result {
      padding: 0 0.5em;
      margin-left: unset;
    }
    .btn-flex {
      display: none;
    }
    .label,
    .data {
      font-size: 14px;
    }
    .check-label {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 576px) {
    .submit {
      padding: 8px 15px;
      font-size: 12px;
      font-weight: 500;
      right: 1em;
    }
    input[type="text"] {
      width: calc(100% - 1em);
    }
  }
`;
