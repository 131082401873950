const PMT = (rate, nperiod, pv, fv, type) => {
  if (!fv) fv = 0;
  if (!type) type = 0;

  if (rate == 0) return -(pv + fv) / nperiod;

  const pvif = (1 + rate) ** nperiod;
  let pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);

  if (type == 1) {
    pmt /= 1 + rate;
  }

  return pmt;
};

const CalculateCredit = (price, duration, apport, credit, data) => {
  const res = {};
  const folderFees = data.folderFees / 100;
  const intrestRate = data.intrestRate / 100;
  const insurance = data.insurance / 100;
  const interimInterest = data.interimInterest / 100;
  const deferredDuration = data.deferredDuration;
  const otherInsurance = data.otherInsurance / 100;
  const residualValue = data.residualValue / 100;
  const tvaIV = data.tvaIV / 100;
  const tva = data.tva / 100;

  // res.prixttc = Math.round(price * tva + price);
  res.prixttc = price;
  res.insurance = data.insurance;
  res.otherInsurance = data.otherInsurance;

  if (credit === "Gratuit") {
    const appor = apport / 100;
    res.apport = Math.round(res.prixttc * appor);
    res.apportFolderFees = Math.round(
      appor * res.prixttc + folderFees * res.prixttc
    );

    res.mensualite = Math.round(res.prixttc * ((1 - appor) / duration));
    res.adi = Math.round((res.prixttc / 1.2 - res.apport) * insurance);
    res.folderFees = (folderFees * 100).toFixed(2);
    res.apportType = "%";
  } else if (credit === "Classique") {
    res.apport = apport;

    res.montant_financé =
      deferredDuration === 0
        ? res.prixttc - res.apport
        : (1 + tvaIV) *
            (interimInterest / 12) *
            (res.prixttc - res.apport) *
            deferredDuration +
          (res.prixttc - res.apport);

    res.gestionFees = Math.round(
      folderFees * (1 + tvaIV) * res.montant_financé
    );

    const rate = (intrestRate * (1 + tvaIV)) / 12;
    const nperiod = duration - deferredDuration;
    const pv = -res.montant_financé;
    res.mensualite = Math.round(PMT(rate, nperiod, pv));
    res.apportFolderFees = Math.round(res.apport + folderFees * res.prixttc);

    res.adi = Math.round(insurance * res.montant_financé);
    res.assurance_vidp = Math.round(
      (otherInsurance * res.montant_financé) / 12
    );
    res.folderFees = (folderFees * 100).toFixed(2);
    res.apportType = "DH";
  } else if (credit == "Leasing") {
    res.apport = apport;
    res.nvApport = apport / res.prixttc;

    res.apportFolderFees = Math.round(res.apport + folderFees * res.prixttc);

    const rate = intrestRate / 12;
    const nperiod = duration;
    const pv =
      -(res.prixttc / 1.2) * (1 - res.nvApport) * (1 + intrestRate / 12);
    const fv = (res.prixttc / 1.2) * residualValue;
    const type = 1;

    res.mensualite = Math.round(PMT(rate, nperiod, pv, fv, type) * 1.2);
    res.adi = Math.round((res.prixttc / 1.2 - res.apport) * insurance);

    res.folderFees = (folderFees * 100).toFixed(2);
    res.apportType = "DH";
  }

  return res;
};

export default CalculateCredit;
