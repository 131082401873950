import styled from "styled-components";

const Popup = ({ setAction, message, setStep, setModel }) => {
  return (
    <Container>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 24 24"
      >
        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z" />
      </svg>

      <h2>Merci!</h2>
      <p>{message}</p>
      <button
        className="popup-btn"
        onClick={() => {
          setAction(0);
          setStep(0);
          setModel(null);
        }}
      >
        Continuer
      </button>
    </Container>
  );
};

export default Popup;

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5000;
  max-width: 400px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 3em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: rgb(237 239 247 / 67%) 0px 10px 20px,
    rgb(237 239 247 / 67%) 0px 6px 6px;
  svg {
    fill: #2d4185;
  }
  h2 {
    font-size: 28px;
    color: #2d4185;
    margin: 1em;
  }
  p {
    font-size: 14px;
    color: #444;
    font-weight: 500;
  }
  .popup-btn {
    padding: 12px 28px;
    border-radius: 4px;
    font-weight: 600;
    white-space: nowrap;
    margin-top: 2em;
    cursor: pointer;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    background: #2d4185;
    &:hover {
      filter: brightness(95%);
    }
  }
  @media only screen and (max-width: 576px) {
    max-width: 330px;
  }
`;
