import React from "react";
import styled from "styled-components";

const Conditions = ({ setIsActive }) => {
  return (
    <Container>
      <div className="wrp-content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          className="close"
          viewBox="0 0 24 24"
          onClick={() => setIsActive(0)}
        >
          <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
        </svg>
        <p>
          Le traitement des données personnelles recueillies du site web{" "}
          <a href="www.autohall.ma" target="_blank" rel="noopener">
            {`www.` + window.location.host}
          </a>{" "}
          est effectué conformément à loi n°09-08 relative à la protection des
          personnes physiques à l’égard des traitements des données à caractère
          personnel.
        </p>
        <p>
          Seuls sont collectées les informations personnelles fournies par
          l’utilisateur avec son consentement explicite.
        </p>
        <p>
          Outre que le traitement des demandes reçues par l’utilisateur via le
          site, les données collectées peuvent être utilisées pour des
          opérations de marketing, des analyses statistiques, le suivi
          commercial ainsi que pour la prise de contact.
        </p>
        <p>
          En vertu de cette même loi, l'Utilisateur dispose d'un droit d'accès,
          de rectification, de suppression et d'opposition de ses données
          personnelles. L'Utilisateur exerce ce droit via :
        </p>
        <ul>
          <li>Le formulaire de contact ;</li>
          <li>par mail à contact@autohall.ma ;</li>
          <li>
            par voie postale à AUTOHALL au Km 12, autoroute Casablanca-Rabat,
            sortie Al Qods, Casablanca 20610 - Maroc
          </li>
        </ul>

        <p>
          Les sources des informations diffusées sur le site sont réputées
          fiables. Toutefois, le site se réserve la faculté d'une non-garantie
          de la fiabilité des sources. Les informations données sur le site le
          sont à titre purement informatif. Ainsi, l'Utilisateur assume seul
          l'entière responsabilité de l'utilisation des informations et contenus
          du présent site.
        </p>
        <p>
          Tout usage du service par l'Utilisateur ayant directement ou
          indirectement pour conséquence des dommages doit faire l'objet d'une
          indemnisation au profit du site.
        </p>
        <p>
          Une garantie optimale de la sécurité et de la confidentialité des
          données transmises n'est pas assurée par le site. Toutefois, le site
          s'engage à mettre en œuvre tous les moyens nécessaires afin de
          garantir au mieux la sécurité et la confidentialité des données.
        </p>
        <p>
          La responsabilité du site ne peut être engagée en cas de force majeure
          ou du fait imprévisible et insurmontable d'un tiers.
        </p>
      </div>
    </Container>
  );
};

export default Conditions;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  .wrp-content {
    padding: 3em 2em;
    border-radius: 20px;
    background: #fff;
    width: 100%;
    max-width: 1100px;
    box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
      rgb(237 239 247 / 47%) 0px 6px 6px;
    position: relative;
  }
  a {
    color: #1066cb;
    display: inline;
  }
  p {
    margin: 0.7em 0;
    text-align: justify;
  }
  li {
    margin: 0.5em 0;
    text-align: justify;
    list-style: disc;
  }
  ul {
    margin-left: 2em;
  }
  .close {
    position: absolute;
    top: 1.5em;
    right: 1.5em;
    cursor: pointer;
    fill: #888;
  }
`;
