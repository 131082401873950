import React from "react";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import App from "./App";
import "./styles/main.scss";
import "rc-slider/assets/index.css";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Simulateur Crédit Auto Hall</title>
        <meta
          name="description"
          content="Simulez votre crédit dès maintenant !"
        />

        <meta itemProp="name" content="Simulateur Crédit Auto Hall" />
        <meta
          itemProp="description"
          content="Simulez votre crédit dès maintenant !"
        />
        <meta
          itemProp="image"
          content="https://media-exp1.licdn.com/dms/image/C560BAQG3kzvWYHh1Qg/company-logo_200_200/0/1519905317494?e=2159024400&v=beta&t=WUNKx5txuRzmlD52ZGk0szVrSQFgDh27xD3o1akayxQ"
        />

        <meta property="og:url" content="http://sim.autohall.ma" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Simulateur Crédit Auto Hall" />
        <meta
          property="og:description"
          content="Simulez votre crédit dès maintenant !"
        />
        <meta
          property="og:image"
          content="https://media-exp1.licdn.com/dms/image/C560BAQG3kzvWYHh1Qg/company-logo_200_200/0/1519905317494?e=2159024400&v=beta&t=WUNKx5txuRzmlD52ZGk0szVrSQFgDh27xD3o1akayxQ"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Simulateur Crédit Auto Hall" />
        <meta
          name="twitter:description"
          content="Simulez votre crédit dès maintenant !"
        />
        <meta
          name="twitter:image"
          content="https://media-exp1.licdn.com/dms/image/C560BAQG3kzvWYHh1Qg/company-logo_200_200/0/1519905317494?e=2159024400&v=beta&t=WUNKx5txuRzmlD52ZGk0szVrSQFgDh27xD3o1akayxQ"
        />
      </Helmet>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
