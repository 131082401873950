import React from "react";
import styled from "styled-components";

const CarCart = ({ car, setModel, color, setIsBrandLink }) => {
  return (
    <Container color={color}>
      <div className="top-side side">
        <img src={car.image} alt="item" />
        <h5>{car.Nom}</h5>
      </div>
      <div className="bottom-side side">
        <h5>{car.Nom}</h5>
        <button
          onClick={() => {
            setModel(car);
            setIsBrandLink(false);
          }}
        >
          Choisir
        </button>
      </div>
    </Container>
  );
};

export default CarCart;

const Container = styled.div`
  background-color: white;
  border-radius: 12px;
  border: 2px solid ${(props) => (props.color ? props.color : "#222")};
  position: relative;
  &:hover {
    .bottom-side {
      opacity: 1;
    }
  }
  .side {
    display: grid;
    grid-template-rows: 1.5fr 0.25fr;
    padding: 1em;
    width: 100%;
    height: 100%;
    transition: all 1s;
  }
  img {
    width: 100%;
    margin: auto;
    height: 150px;
    object-fit: contain;
  }
  h5 {
    color: ${(props) => (props.color ? props.color : "#222")};
    font-size: 16px;
    font-weight: 600;
  }
  h6 {
    font-size: 15px;
    font-weight: 500;
  }
  .bottom-side {
    grid-template-rows: 1.5fr 0.25fr 0.25fr;
    background: ${(props) => (props.color ? props.color : "#222")};
    align-items: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.4s;
    h5,
    h6 {
      color: #fff;
      margin: 5px 0;
    }
  }
  button {
    padding: 7px 32px;
    color: ${(props) => (props.color ? props.color : "#222")};
    background: #fff;
    border-radius: 100px;
    margin-top: 1.5em;
    font-weight: 600;
    font-size: 14px;
  }
`;
