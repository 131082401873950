import download from "downloadjs";

const { REACT_APP_BACKEND_URL, REACT_APP_API_URL } = process.env;

const GetLocalBrands = async () => {
  try {
    const response = await fetch(REACT_APP_BACKEND_URL + "/brands", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

const GetCities = async () => {
  try {
    const response = await fetch(REACT_APP_BACKEND_URL + "/cities", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

const GetBrands = async () => {
  try {
    const response = await fetch(REACT_APP_API_URL + "/vehicle/allbrands", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const res = await response.json();
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const GetCars = async (id) => {
  try {
    const response = await fetch(
      `
      ${REACT_APP_API_URL}/vehicle/brand/${id}/models`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const res = await response.json();
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const GetModel = async (id) => {
  try {
    const response = await fetch(
      `
      ${REACT_APP_API_URL}/vehicle/model/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const res = await response.json();
    return res.data[0];
  } catch (err) {
    console.log(err);
  }
};

const GetVersion = async (id) => {
  try {
    const response = await fetch(
      `
      ${REACT_APP_API_URL}/vehicle/version/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const res = await response.json();
    return res.data[0];
  } catch (err) {
    console.log(err);
  }
};

const GetVersions = async (id) => {
  try {
    const response = await fetch(
      `
      ${REACT_APP_API_URL}/vehicle/model/${id}/versions`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const res = await response.json();

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const GetSegments = async () => {
  try {
    const response = await fetch(
      REACT_APP_API_URL + "/vehicle/segments/all",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const res = await response.json();
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const GetClientCategories = async () => {
  try {
    const response = await fetch(REACT_APP_BACKEND_URL + "/clientCategories", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

const GetEligibility = async (vehicle, client) => {
  try {
    const response = await fetch(
      REACT_APP_BACKEND_URL + `/creditEligibility/${vehicle}/${client}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

const AddSimulation = async (
  clientInfos,
  profession,
  credit,
  duration,
  apport,
  price,
  results,
  brand,
  model
) => {
  const body = {
    name: clientInfos.name,
    email: clientInfos.email,
    phone: clientInfos.phone,
    city: clientInfos.city,
    profession,
    credit,
    duration,
    apport,
    global_cost: price,
    monthly_payment: results.mensualite,
    adi: results.adi,
    brand: brand,
    model: model,
  };
  try {
    await fetch(REACT_APP_BACKEND_URL + "/simulation", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
    return true;
  } catch (error) {
    console.log(error);
  }
};

const GetParams = async (credit, vehicle, client) => {
  try {
    const response = await fetch(
      REACT_APP_BACKEND_URL + `/creditParam/${credit}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    const res = await response.json();
    let data = null;
    let nbr = 4;

    res.map((item) => {
      const obj = {
        tva: item.params_tva,
        tvaIV: item.params_tvaIV,
        tvaLoc: item.params_tvaLoc,
        insurance: item.params_insurance,
        otherInsurance: item.params_otherInsurance,
        deferredDuration: item.params_deferredDuration,
        interimInterest: item.params_interimInterest,
        durationMax: item.params_durationMax,
      };
      if (
        item.params_vehicleType === vehicle &&
        item.params_clientCategory === client
      ) {
        data = obj;
        nbr = 1;
      } else if (nbr > 2 && item.params_vehicleType === vehicle) {
        data = obj;
        nbr = 2;
      } else if (nbr > 3) {
        data = obj;
        nbr = 3;
      }
    });

    return data;
  } catch (err) {
    console.log(err);
  }
};

const GetScenarios = async (
  brand,
  credit,
  vehicle,
  client,
  apport,
  duration
) => {
  try {
    const response = await fetch(
      REACT_APP_BACKEND_URL + `/scenario/${brand}/${credit}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    const res = await response.json();
    let data = null;
    let nbr = 6;

    res.map((item) => {
      const obj = {
        folderFees: item.scenario_folderFees,
        intrestRate: item.scenario_intrestRate,
        residualValue: item.scenario_residualValue,
      };

      if (
        item.scenario_clientCategory === client &&
        item.scenario_vehicleType === vehicle &&
        item.scenario_apport == apport &&
        item.scenario_duration == duration
      ) {
        data = obj;
        nbr = 1;
      } else if (
        nbr > 2 &&
        item.scenario_clientCategory === client &&
        item.scenario_vehicleType === vehicle &&
        item.scenario_apport == apport
      ) {
        nbr = 2;
      } else if (
        nbr > 3 &&
        item.scenario_vehicleType === vehicle &&
        item.scenario_clientCategory === client
      ) {
        data = obj;
        nbr = 3;
      } else if (nbr > 4 && item.scenario_vehicleType === vehicle) {
        nbr = 4;
        data = obj;
      } else if (nbr > 5) {
        data = obj;
        nbr = 5;
      }
    });

    if (nbr === 2) {
      const resp = await fetch(
        REACT_APP_BACKEND_URL +
          `/scenarioByDuration/${brand}/${credit}/${vehicle}/${client}/${duration}/${apport}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      const result = await resp.json();
      data = {
        folderFees: result.scenario_folderFees,
        intrestRate: result.scenario_intrestRate,
        residualValue: result.scenario_residualValue,
      };
    }

    return data;
  } catch (err) {
    console.log(err);
  }
};

const saveAsFile = (byteData, fileName, mimType) => {
  const linkSource = `data:${mimType};base64,${byteData}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const Create = async (
  credit,
  results,
  price,
  duration,
  apport,
  clientInfos,
  model
) => {
  const body = {
    credit,
    results,
    price,
    duration,
    apport,
    clientInfos,
    model,
  };

  try {
    const responce = await fetch(REACT_APP_BACKEND_URL + "/pdf", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
    const res = await responce.text();
    // download(bytes, "simulation.pdf", "application/pdf");
    saveAsFile(res, "simulation", "application/pdf");
    console.log(res);
  } catch (error) {
    console.log(error);
  }
};

export {
  GetLocalBrands,
  GetCities,
  GetBrands,
  GetCars,
  GetClientCategories,
  GetEligibility,
  AddSimulation,
  GetParams,
  GetScenarios,
  GetVersions,
  GetSegments,
  GetModel,
  GetVersion,
  Create,
};
